( function( $, window, document, undefined ) {

	const disableBodyScroll = bodyScrollLock.disableBodyScroll;
	const enableBodyScroll = bodyScrollLock.enableBodyScroll;
	const targetElement = document.querySelector('#js-site-header');

	$nav = $('.site-nav__list');
	$hamburger = $('.hamburger');

	$hamburger.on('click', function() {
		if ( $hamburger.hasClass('is-active') ) {
			closeMenu();
		} else {
			openMenu();
		}
	});

	function closeMenu() {
		$hamburger.removeClass('is-active');
		$nav.removeClass('is-open');
		enableBodyScroll(targetElement);
	}

	function openMenu() {
		$hamburger.addClass('is-active');
		$nav.addClass('is-open');
		disableBodyScroll(targetElement);
	}

} )( jQuery, window, document );
