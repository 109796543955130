(function ($) {

	var extra;

	// Select all links with hashes
	$('a[href*="#"]')
		// Remove links that don't actually link to anything
		.not('[href="#"]')
		.not('[href="#0"]')
		.not('[role="tab"]')
		.click(function (event) {
			// On-page links
			if (
				location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
				location.hostname == this.hostname
			) {
				// Figure out element to scroll to
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					if ($(this).hasClass('extra-scroll')) {
						extra = 150;
					} else {
						extra = 0;
					}

					event.preventDefault();
					$('html, body').animate({
						scrollTop: target.offset().top - extra
					}, 350, function () {
						// Callback after animation
						// Must change focus!

						target[0].focus({
							preventScroll: true
						});

						// check if target was actually focused
						if (target[0] != document.activeElement) {
							// if not, set tabindex and focus again
							target[0].setAttribute('tabindex', '-1');
							target[0].focus({
								preventScroll: true
							});
						}

					});
				}
			}
		});

})(jQuery);