(function ($) {

    // initTab('.tabs');

    $('.js-tabs').each((index, tabsContainer) => {
        let tablist = tabsContainer.querySelector('ul');
        let tabs = tabsContainer.querySelectorAll('a');
        let panels = tabsContainer.querySelectorAll('.tab-panel');

        tablist.setAttribute('role', 'tablist');

        Array.prototype.forEach.call(tabs, (tab, i) => {
            // initial semantics
            tab.setAttribute('role', 'tab');
            tab.parentNode.setAttribute('role', 'presentation');
            // make unfocusable by default, first will be made focusable later
            tab.setAttribute('tabindex', '-1');

            $(tab).click(function(event) {
                if ( tab.hasAttribute('aria-selected') ) {
                    event.preventDefault();
                    return false;
                }

                switchTab(tab);
                event.preventDefault();
                return false;
            });

            $(tab).on('keydown', function(event) {
                let keys = {
                    'ArrowLeft': -1,
                    'ArrowRight': 1,
                    'ArrowDown': 'down'
                };
                let dir = keys[event.key];

                // unsupported key pressed
                // or tab to switch to doesn't exist
                if ( !dir || ('down' !== dir && !tabs[i + dir] ) ) { 
                    return;
                }

                event.preventDefault();

                if ( 'down' === dir ) {
                    panels[i].focus();
                    return;
                }

                switchTab(tabs[i +  dir]);
            });
        });

        Array.prototype.forEach.call(panels, (panel, i) => {
            // initial semantics, make focusable
            panel.setAttribute('role', 'tabpanel');
            panel.setAttribute('tabindex', '-1');
            // hide all by default, first will be shown later
            panel.hidden = true;
        });

        /**
         * Move to a new tab.
         * @param  {Element} newTab A role="tab" element to make active.
         */
        function switchTab( newTab ) {
            let oldTab = tablist.querySelector('[aria-selected]');
            let newIndex = Array.prototype.indexOf.call(tabs, newTab);

            if(newTab){
                newTab.setAttribute('aria-selected', 'true');
                newTab.removeAttribute('tabindex');
                panels[newIndex].hidden = false;
    
                // allows function to be used to init first tab
                if ( oldTab ) {
                    let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
    
                    oldTab.removeAttribute('aria-selected');
                    oldTab.setAttribute('tabindex', '-1');
    
                    panels[oldIndex].hidden = true;
    
                    // only move focus when moving from a previous tab
                    newTab.focus();
                }
            }

            
        }

        // open first tab and panel by default
        switchTab(tabs[0]);
    });

})(jQuery);
