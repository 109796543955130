(function ($) {

	$.fn.visibleHeight = function () {
		var elBottom, elTop, scrollBot, scrollTop, visibleBottom, visibleTop;
		scrollTop = $(window).scrollTop();
		scrollBot = scrollTop + $(window).height();
		elTop = this.offset().top;
		elBottom = elTop + this.outerHeight();
		visibleTop = elTop < scrollTop ? scrollTop : elTop;
		visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
		return visibleBottom - visibleTop;
	};

	const $siteHeader = $(".js-site-header");
	const $sectionNavigation = $(".toc");
	const $sectionNavigationContainer = $(".toc--container");

	if (!$sectionNavigation.length) {
		return;
	}

	setCompactClass();
	stickyNavInit();

	setTimeout(function () {
		$(window).trigger('resize');
	}, 1000);



	$(window).on('resize', throttle(function () {
		setCompactClass();
		stickyNavInit();
	}, 25));

	$(window).on('scroll', throttle(function () {
		stickyNavInit();
	}, 25));

	function setCompactClass() {
		var availablespace = $sectionNavigationContainer.outerWidth(true);

		if (calc_widths() + 50 > availablespace) {
			$sectionNavigationContainer.addClass('compact');
		} else {
			$sectionNavigationContainer.removeClass('compact');
			$sectionNavigation.show();

		}
	}

	function calc_widths() {
		var navwidth = 0;
		$('.toc__list li').each(function () {
			navwidth += $(this).data('width');
		});
		return navwidth;
	}

	function stickyNavInit() {
		let navPrimaryHeight = $siteHeader.visibleHeight();
		let navSecondaryHeight = $sectionNavigation.visibleHeight();
		let navSecondaryTop = $sectionNavigationContainer.offset().top;

		if (navSecondaryTop <= ($(window).scrollTop() + navPrimaryHeight)) {
			$sectionNavigation.addClass("nav-fixed").css({ 'top': navPrimaryHeight });
			$sectionNavigationContainer.css({ 'padding-top': navSecondaryHeight });
		} else {
			$sectionNavigation.removeClass("nav-fixed").css({ 'top': 0 });
			$sectionNavigationContainer.css("padding-top", 'inherit');
		}
	}
})(jQuery);

(function( $ ) {

	const $sections = $('.property-section');
	const $header = $('.toc');
	const $nav = $('.extra-scroll');

	$(window).on('load scroll', function() {
		$sections.each( function() {
			var top_of_element = $(this).offset().top;
		    var bottom_of_header = $header.offset().top + $header.outerHeight();

		    var section_id = $(this).attr('id');

		    if ( top_of_element <= ( bottom_of_header + 1 ) ) {

		    	var $active_nav = $('.extra-scroll.' + section_id);

		    	if ( !$active_nav.hasClass('is-current') ) {
		    		$nav.removeClass('is-current');
		    		$active_nav.addClass('is-current');
		    	}
		    }
		});
	});
	
})( jQuery );
