(function ($) {

    function set_validation_errors(errors) {

        $('.input').removeClass('has-error');
        $('.input-message--error').text('');

        for (var error in errors) {
            $('.error-' + error).text(errors[error]);
            $('.input-' + error).addClass('has-error');
        }

        let element = $(".has-error").filter(function () {
            return $(this).children(':first');
        });

        $('html, body').animate({
            scrollTop: $(element).offset().top - $('.site-header').outerHeight(false) - $('.toc').outerHeight(false) - 40
        }, 300);
        
        return true;
    }

    function reset_form(elements) {
        document.getElementById("contact_form").reset();
        $('.input').removeClass('has-error');
        $('.input-message--error').text('');
    }

    $(document).ready(function () {
        $('body').on('click', '.submit', function (e) {
            e.preventDefault();
            var data = {
                'name': $('.input-name').val(),
                'email': $('.input-email').val(),
                'telephone': $('.input-telephone').val(),
                'comments': $('.input-comments').val(),
                'url': $('.input-url').val(),
                'page_slug': $('.input-page_slug').val(),
                '_accept_terms': $('.input-accept_terms').is(":checked") ? 1 : 0,
            };

            $.ajax({
                url: '/ajax-contact-form/',
                type: "POST",
                data: data, 
                dataType: "json",
                statusCode: {
                    403: function (xhr) {
                        if ( xhr.responseJSON.errors ) {
                            set_validation_errors(xhr.responseJSON.errors);
                        } else {
                            $('#result').html(xhr.responseJSON.message);
                        }
                    },
                    200: function (xhr) {
                        $('#result').html(xhr.message);
                        reset_form();
                    }
                }
            });
        });
    });

})(jQuery);
