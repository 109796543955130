( function( $, window, document, undefined ) {

	$hero = $('.hero');
	$header = $('.site-header');

	if ( $hero.length ) {
	
		$(window).on('scroll resize', function() {
			scrollTop = $(window).scrollTop();
			heroHeight = $hero.outerHeight();

			if ( scrollTop >= heroHeight && !$header.hasClass('is-sticky') ) {
				$header.addClass('is-sticky');

			} else if ( scrollTop < heroHeight && $header.hasClass('is-sticky') ) {
				$header.addClass('swipe-up');

				setTimeout(function(){
					$header.removeClass('is-sticky');
					$header.removeClass('swipe-up');
				}, 200);
			}

		});

	}

} )( jQuery, window, document );
